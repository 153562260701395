import React, { Component } from 'react';
import countapi from 'countapi-js';

class Like extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    document.getElementById('magic-button').addEventListener('click', () =>
      countapi.event('magic-button').then((result) => this.setState({ like: result.value }) ));
  }


  render() {

    return (
      <div><button id="magic-button" style={{backgroundColor: '#030f29', border: '0px'}}>👍</button><span>{this.state.like}</span></div>
    );

  }
}

export default Like;